import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  useInvoicePreColumns,
  useOrderingLucilaPreColumns,
} from '../../constants';
import { CLIENT_ORDERING_COLUMNS } from '../../constants/risk';
import { formatMoney, getAmortizationStatus } from '../../helpers';
import FingoDataGrid from '../dataGrids/FingoDataGrid';
import BoxCellsGrid from '../grids/BoxCellsGrid';
import {
  CLIENT_INVOICES_STATUS_INFORMATION,
  CLIENT_ORDERING_INFORMATION,
  CLIENT_ORDERING_PROFILE,
} from '../../graphql';
import FingoMainView from '../layout/FingoMainView';
import dayjs from '../../config/dayjs';

const ClientOrderingInformation = ({ masterEntity, amortizationData }) => {
  const sinceDate = useMemo(
    () => dayjs().subtract(6, 'months').format('YYYY-MM-DD'),
    [],
  );
  const { data: orderingData, loading: loadingOrderingData } = useQuery(
    CLIENT_ORDERING_INFORMATION,
    {
      variables: {
        companyId: masterEntity?.id,
      },
      skip: !masterEntity,
    },
  );
  const { data: invoicesData, loading: loadingInvoicesData } = useQuery(
    CLIENT_INVOICES_STATUS_INFORMATION,
    {
      variables: {
        companyId: masterEntity?.id,
        dateIssued_Gte: sinceDate,
      },
      skip: !masterEntity,
    },
  );
  const {
    amountRejectedInvoices,
    amountCreditNoteInvoices,
    amountOfPendingReintegrations,
  } = invoicesData || {};

  const { exposedPurchaseOrders, debtPurchaseOrders, finishedPurchaseOrders } = orderingData || {};

  const amortizationStatus = amortizationData
    ? getAmortizationStatus(amortizationData, 'purchaseorder')
    : { title: formatMoney(0), subtitle: 'Sin plan de pago' };

  const summaryBoxes = [
    {
      header: 'Información Rechazos',
      title: formatMoney(amountRejectedInvoices?.totalCount || 0),
      subtitle: 'Rechazos (Sii)',
    },
    {
      header: 'Información NC',
      title: formatMoney(amountCreditNoteInvoices?.totalCount || 0),
      subtitle: ' NC (Sii)',
    },
    {
      header: 'Información Reintegros',
      title: formatMoney(amountOfPendingReintegrations?.totalCount || 0),
      subtitle: 'Facturas pendientes para reintegros',
    },
    {
      header: 'Plan de pago',
      title: amortizationStatus.title,
      subtitle: (
        <Typography
          sx={{
            color:
              // eslint-disable-next-line no-nested-ternary
              amortizationStatus.subtitle === 'Terminado'
                ? '#FF9801'
                // eslint-disable-next-line no-nested-ternary
                : amortizationStatus.subtitle === 'Activo'
                  ? '#DD362E'
                  : amortizationStatus.subtitle === 'Sin plan de pago'
                    ? '#287A23'
                    : 'inherit',
          }}
        >
          {amortizationStatus.subtitle}
        </Typography>
      ),
    },
  ];

  const documentBoxes = [
    {
      header: 'Exposición Actual Vigente',
      title: `$${formatMoney(exposedPurchaseOrders?.aggregate || 0)}`,
      subtitle: `${exposedPurchaseOrders?.totalCount} OC`,
    },
    {
      header: 'Exposición Actual en Mora',
      title: `$${formatMoney(debtPurchaseOrders?.aggregate || 0)}`,
      subtitle: `${debtPurchaseOrders?.totalCount} OC`,
    },
    {
      header: 'Operaciones Históricas',
      title: `$${formatMoney(finishedPurchaseOrders?.aggregate || 0)}`,
      subtitle: `${finishedPurchaseOrders?.totalCount} OC`,
    },
  ];

  const columns = useOrderingLucilaPreColumns(useInvoicePreColumns());

  return (
    <>
      <BoxCellsGrid
        options={summaryBoxes}
        loading={loadingInvoicesData}
        rowSpacing={5}
        mt={0.05}
        mb={4}
      />
      <BoxCellsGrid
        options={documentBoxes}
        rowSpacing={5}
        pt={2}
        mb={5}
        loading={loadingOrderingData}
      />
      <Typography color="primary" variant="h6" sx={{ marginBottom: 1 }}>
        <b>Documentos en Cartera:</b>
      </Typography>
      <Box sx={{ height: 350 }}>
        <FingoMainView
          id="client-factoring-datagrid"
          query={CLIENT_ORDERING_PROFILE}
          queryCustomVariables={{
            companyId: masterEntity?.id,
            inOperation: true,
          }}
          hideReloadButton
          slots={{
            table: FingoDataGrid,
          }}
          slotProps={{
            table: {
              columns: columns.filter((c) => CLIENT_ORDERING_COLUMNS.includes(c.field)),
              noRowsMessage: () => <Typography>Sin documentos</Typography>,
              rowsPerPageOptions: [25, 50, 100],
            },
          }}
        />
      </Box>
    </>
  );
};

ClientOrderingInformation.propTypes = {
  masterEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      pendingReintegrations: PropTypes.shape({
        count: PropTypes.number,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  amortizationData: PropTypes.shape({
    amortizationSchedules: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ClientOrderingInformation;
