import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  useInvoiceLucilaCustomColumns,
  useInvoicePreColumns,
} from '../../constants';
import { DEBTOR_FACTORING_COLUMNS } from '../../constants/risk';
import { formatMoney } from '../../helpers';
import FingoDataGrid from '../dataGrids/FingoDataGrid';
import BoxCellsGrid from '../grids/BoxCellsGrid';
import {
  CLIENT_FACTORING_INFORMATION,
  FACTORING_PROFILE_INVOICES,
  MASTER_ENTITY_PROFILE_AS_DEBTOR,
} from '../../graphql';
import FingoMainView from '../layout/FingoMainView';
import dayjs from '../../config/dayjs';

const DebtorFactoringInformation = ({ masterEntityId, type }) => {
  const [selectedTab, setSelectedTab] = useState('exposed');
  const { data: entity, loading: loadingEnt } = useQuery(
    MASTER_ENTITY_PROFILE_AS_DEBTOR,
    {
      variables: { masterEntityId },
      skip: !masterEntityId,
    },
  );
  const { data, loading: loadingFactoringData } = useQuery(
    CLIENT_FACTORING_INFORMATION,
    {
      variables: {
        receiverId: masterEntityId,
      },
      skip: !masterEntityId,
    },
  );
  const { exposedInvoices, debtInvoices, finishedInvoices } = data || {};

  const summaryBoxes = [
    {
      header: 'Información Pagos',
      title: entity?.staffMasterEntity?.invoicesAgreedPaymentTermAverage,
      subtitle: 'Plazo de pago promedio acordado',
      addedValue: 'días',
    },
    {
      header: 'Información Pagos',
      title: entity?.staffMasterEntity?.invoicesActualPaymentTerm,
      subtitle: 'Plazo de pago promedio real',
      addedValue: 'días',
    },
    {
      header: 'Ratificación promedio',
      title: `${dayjs
        .utc(entity?.staffMasterEntity?.avgRatificationDuration || 0 * 1000)
        .format('HH:mm:ss')}`,
      subtitle: 'Tiempo promedio en ratificar las facturas',
    },
  ];

  const documentBoxes = [
    {
      header: 'Exposición Actual Vigente',
      title: `$${formatMoney(exposedInvoices?.aggregate || 0)}`,
      subtitle: `${exposedInvoices?.totalCount} facturas`,
    },
    {
      header: 'Exposición Actual en Mora',
      title: `$${formatMoney(debtInvoices?.aggregate || 0)}`,
      subtitle: `${debtInvoices?.totalCount} facturas`,
    },
    {
      header: 'Operaciones Históricas',
      title: `$${formatMoney(finishedInvoices?.aggregate || 0)}`,
      subtitle: `${finishedInvoices?.totalCount} facturas`,
    },
  ];

  const columns = useInvoicePreColumns(useInvoiceLucilaCustomColumns());
  const statusShowed = useMemo(() => {
    if (selectedTab === 'exposed') return ['Transfered', 'Debt'];
    return ['Finished'];
  }, [selectedTab]);
  return (
    <>
      <BoxCellsGrid
        options={summaryBoxes}
        rowSpacing={5}
        mt={0.05}
        mb={4}
        loading={loadingEnt}
      />
      <BoxCellsGrid
        options={documentBoxes}
        loading={loadingFactoringData}
        rowSpacing={5}
        pt={2}
        mb={5}
      />
      {type === 'transmitter' && (
        <>
          <Tabs
            value={selectedTab}
            onChange={(_, value) => setSelectedTab(value)}
            centered
          >
            <Tab label="Exposición Vigente" value="exposed" />
            <Tab label="Operaciones Históricas" value="finished" />
          </Tabs>
          <Box sx={{ height: 350 }}>
            <FingoMainView
              id="debtor-factoring-datagrid"
              query={FACTORING_PROFILE_INVOICES}
              queryCustomVariables={{
                receiverId: masterEntityId,
                status: statusShowed,
              }}
              hideReloadButton
              slots={{
                table: FingoDataGrid,
              }}
              slotProps={{
                table: {
                  columns: columns.filter((c) => DEBTOR_FACTORING_COLUMNS.includes(c.field)),
                  noRowsMessage: () => <Typography>Sin documentos</Typography>,
                  rowsPerPageOptions: [25, 50, 100],
                },
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};

DebtorFactoringInformation.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default DebtorFactoringInformation;
