import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { FingoDataGrid } from '../dataGrids';
import useInstallmentColumns from '../../constants/useInstallmentColumns';
import dayjs from '../../config/dayjs';

const Installments = ({ row }) => {
  const { installments } = row;
  const orderedInstallments = useMemo(
    () => [...installments].sort(
      (a, b) => new Date(a.expirationDate) - new Date(b.expirationDate),
    ),
    [installments],
  );
  return (
    <Box height={300}>
      <FingoDataGrid
        columns={useInstallmentColumns()}
        rows={orderedInstallments}
        hideFooter
        serverFilters={false}
        orderBy="expirationDate"
        getRowId={(_row) => _row.id}
      />
    </Box>
  );
};

Installments.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    installments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        expirationDate: PropTypes.objectOf(dayjs).isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default Installments;
